/* eslint no-console:1 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//import regula from 'custom/regula-1.3.4'

require("packs/common")
require("custom/photoswipe.min")
require("custom/photoswipe-ui-default.min")
import PhotoSwipe from 'custom/photoswipe.min';
global.PhotoSwipe = PhotoSwipe;
import PhotoSwipeUI_Default from 'custom/photoswipe-ui-default.min';
global.PhotoSwipeUI_Default = PhotoSwipeUI_Default;


require("custom/core.min")
require("custom/html5shiv.min")
require("custom/pointer-events.min")
require("custom/jquery.rd-navbar")
require("custom/select2.full")
document.addEventListener("turbolinks:load", () => {
  $("a[rel~=popover], .has-popover").popover();
  $("a[rel~=tooltip], .has-tooltip").tooltip();
});

document.addEventListener('DOMContentLoaded', function() {
  $("input").each(function(){
    var el = document.createElement('label');
    $( this ).parent().append(el);
  })
});


document.addEventListener('DOMContentLoaded', function() {

  require("custom/script")
  require("custom/jquery.ui.totop")
  $(".tooltip-toggle").each(function(){
    var el = document.createElement('svg');
    el.innerHTML = ' <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg"><g fill="green" fill-rule="evenodd"><path d="M13.5 27C20.956 27 27 20.956 27 13.5S20.956 0 13.5 0 0 6.044 0 13.5 6.044 27 13.5 27zm0-2C7.15 25 2 19.85 2 13.5S7.15 2 13.5 2 25 7.15 25 13.5 19.85 25 13.5 25z"/><path d="M12.05 7.64c0-.228.04-.423.12-.585.077-.163.185-.295.32-.397.138-.102.298-.177.48-.227.184-.048.383-.073.598-.073.203 0 .398.025.584.074.186.05.35.126.488.228.14.102.252.234.336.397.084.162.127.357.127.584 0 .22-.043.412-.127.574-.084.163-.196.297-.336.4-.14.106-.302.185-.488.237-.186.053-.38.08-.584.08-.215 0-.414-.027-.597-.08-.182-.05-.342-.13-.48-.235-.135-.104-.243-.238-.32-.4-.08-.163-.12-.355-.12-.576zm-1.02 11.517c.134 0 .275-.013.424-.04.148-.025.284-.08.41-.16.124-.082.23-.198.313-.35.085-.15.127-.354.127-.61v-5.423c0-.238-.042-.43-.127-.57-.084-.144-.19-.254-.318-.332-.13-.08-.267-.13-.415-.153-.148-.024-.286-.036-.414-.036h-.21v-.95h4.195v7.463c0 .256.043.46.127.61.084.152.19.268.314.35.125.08.263.135.414.16.15.027.29.04.418.04h.21v.95H10.82v-.95h.21z"/></g></svg>'
    $( this ).append(el);
  });
});

